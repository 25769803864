











































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";

const RH = new RequestHandler();

@Component
export default class ForgotUserPass extends Vue {
  private username = "";
  private email = "";
  private usernameErrorMessage = "";
  private emailErrorMessage = "";

  private async submitPasswordForm() {
    this.usernameErrorMessage = "";
    if (this.username === "") {
      this.usernameErrorMessage = "Please enter an username.";
    } else {
      try {
        await RH.sendForgotPasswordEmail(this.username);
        this.$root.$emit("snackbar-message", "Email sent successfully.");
        this.username = "";
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Failed to send email. Please try again."
        );
      }
    }
  }

  private async submitUsernameForm() {
    this.emailErrorMessage = "";
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.email === "" || !emailRegex.test(this.email)) {
      this.emailErrorMessage = "Please enter a valid email.";
    } else {
      try {
        await RH.sendForgotUsernameEmail(this.email);
        this.$root.$emit("snackbar-message", "Email sent successfully.");
        this.email = "";
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Failed to send email. Please try again."
        );
      }
    }
  }
}
